<template>
  <div>

  </div>
</template>

<script>
export default {
  name: "mobile_industry_relationship",
}
</script>

<style scoped>

</style>